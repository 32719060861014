<template>
    <section>
        <VueSlickCarousel v-bind="settings">
            <div class="slide">
                <!-- écran large -->
                <div class="part-one d-flex flex-column align-self-center text-center px-4 computer">
                    <tt-editor v-if="editable" :value="text1" @input="changeText($event,'text1')" ></tt-editor>
                    <span v-else v-html="text1"></span>
                    <div v-if="editable">
                        <add-image
                            modal='meb-carousel-image-1'
                            @saveImage="saveImage($event,'image1')"
                        />
                    </div>
                </div>
                <div class="part-two computer">
                    <picture>
                        <source class="card-img-top"  :srcset="image1.webp" type="image/webp">
                        <img class="carousel-img" slot="img" :src="image1.original" alt="slide image" loading="lazy">
                    </picture>
                </div>
            </div>

            <div class="slide">
                 <!-- écran large -->
                <div class="part-one computer">
                     <picture>
                        <source class="card-img-top"  :srcset="image2.webp" type="image/webp">
                        <img class="carousel-img" slot="img" :src="image2.original" alt="slide image" loading="lazy">
                    </picture>
                </div>
                <div class="part-two d-flex flex-column align-self-center text-center px-5 computer">
                    <tt-editor v-if="editable" :value="text2" @input="changeText($event,'text2')" ></tt-editor>
                    <span v-else v-html="text2"></span>
                    <div v-if="editable">
                        <add-image
                            modal="meb-carousel-image-2"
                            @saveImage="saveImage($event,'image2')"
                        />
                    </div>
                </div>

            </div>

            <div class="slide">
                <!-- écran large -->
                <div class="part-one d-flex flex-column align-self-center text-center px-5 computer">
                    <tt-editor v-if="editable" :value="text3" @input="changeText($event,'text3')" ></tt-editor>
                    <span v-else v-html="text3"></span>
                    <div v-if="editable">
                        <add-image
                            modal='meb-carousel-image-3'
                            @saveImage="saveImage($event,'image3')"
                        />
                    </div>
                    <router-link to="/boutique"><button class="btn btn-gradient-primary" >Boutique</button></router-link>    
                </div>
                <div class="part-two computer">
                    <picture>
                        <source class="card-img-top"  :srcset="image2.webp" type="image/webp">
                        <img class="carousel-img" slot="img" :src="image2.original" alt="slide image" loading="lazy">
                    </picture>
                </div>
                <!-- mobile -->
            </div>
        </VueSlickCarousel>
    </section>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import ttEditor from '../tt-editor.vue'
import addImage from '../../functions/add-image.vue'
export default {
    name: "carousel-meb",
    components: {
        VueSlickCarousel,
        ttEditor,
        addImage
    },
    data () {
        return {
            // paramètre vueslick
            settings: {
                "dots": true,
                "dotsClass": "slick-dots custom-dot-class",
                "edgeFriction": 0.35,
                "infinite": true,
                "speed": 800,
                // "autoplay": true,
                "autoplaySpeed":6500,
                "slidesToShow": 1,
                "slidesToScroll": 1,
                "pauseOnDotsHover": true,
                "pauseOnFocus": true,
                "pauseOnHover": true
            }
        }
    },
    props:{
        editable: Boolean,
        image1:{
            type:Object,
            default: ()=> {return {
                original:"@/assets/images/import/meb/prestations.png",
                webp:"@/assets/images/import/meb/prestations.png"
            }}
        },
        text1:{
            type:String,
            default:'<h3 class="mb-4">Prestations</h3>'+
                    '<p>De la tête aux pieds, des soins au maquillage en passant par le conseil en image, de l\'Afrique à l\'Asie en passant par l\'Europe, venez voyager vers la beauté et le bien-être !</p>'
        },
        image2:{
            type:Object,
            default: ()=> {return {
                original:"@/assets/images/import/meb/bathroom.jpg",
                webp:"@/assets/images/import/meb/bathroom.jpg"
            }}
        },
        text2:{
            type:String,
            default:"<h3>Mon institut</h3>"+
                    "<p class='mb-3'>Dans une rue très passante et commerçante de Gaillac, aucun stress pour se garer, vous aurez à votre disposition un parking devant l'institut."+
                    "Vous trouverez deux cabines de soin de 12m² avec un esprit cocooning ainsi qu'un magasin lumineux comprenant un espace maquillage et onglerie.</p>"
        },
        image3:{
            type:Object,
            default: ()=> {return {
                original:"@/assets/images/import/meb/prestations.png",
                webp:"@/assets/images/import/meb/prestations.png"
            }}
        },
        text3:{
            type:String,
            default:"<h3 class='mb-4'>Ma sélection de produits</h3>"+
                    "<p>Je fonctionne exclusivement avec les marques Nohèm, 1944 Paris et Beauty Garden. Je les utilise depuis longtemps et je les ai sélectionnées pour la qualité de leurs produits. La plupart des produits sont issus d'extrait naturel et portent le label BIO.</p>"
        }
    },
    methods:{
        changeText(payload, element){
            this.$emit('changeTextMebCarousel',{
                'element':element,
                'data':payload
            })
        },
        saveImage(image,element){
            this.$emit("changeImage", {
                'image':image,
                'element':element
            });
        }
    }
}
</script>
<style scoped>
@media screen and (max-width: 992px) {
    /* si sur mobile */
    .carousel-img {
        height: 37rem !important;
        object-fit: cover;
    }
    .carousel-mobile .position-absolute {
        top: 0;
        height: 100%;
    }
    .text {
        background: rgba(255, 255, 255, 0.808);
    }
}
@media screen and (min-width: 992px) {
    /* si sur écran large (ordinateur) */ 
    .slide {
        display: grid !important;
        grid-template-columns: 50% 50%;
    }
    img {
        width: 100%;
        object-fit: cover;
    }
}

img{
    width: 100%;
    pointer-events: none;
}

::v-deep .slick-dots {
    margin-bottom: 30px
}
</style>